import React, { useState } from "react"
import { graphql } from "gatsby"
import Layout from "../../../components/layouts"
import Banner from "../../../components/common/Banner"
import { Link } from "gatsby"
import SEO from "../../../components/common/SEO"
import FsLightbox from "fslightbox-react"
import Collapsible from "react-collapsible"
import ReactWOW from "react-wow"
// Query for the Course content in Prismic
export const query = graphql`
  query CourseQuery($uid: String) {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl: url
        defaultImage: image
        twitterUsername
      }
    }
    course: prismic {
      allCourses(uid: $uid) {
        edges {
          node {
            description
            name
            outline {
              lesson_name
              lesson_duration
              lesson_preview {
                ... on PRISMIC__ExternalLink {
                  url
                }
              }
            }
            safety_image
            screenshots {
              screenshot
            }
            social_image
            social_description
            social_title
            meta_title
            meta_description
          }
        }
      }
    }
  }
`

function timeConvert(n) {
  var num = n
  var hours = num / 60
  var rhours = Math.floor(hours)
  var minutes = (hours - rhours) * 60
  var rminutes = Math.round(minutes)

  if (rhours > 0 && rminutes > 0) {
    return rhours + " hr(s) and " + rminutes + " min(s)"
  } else if (rhours > 0) {
    return rhours + " hr(s)"
  } else if (rminutes > 0) {
    return rminutes + " min(s)"
  }
}

// Display the title, date, and content of the Post
const CourseBody = ({ course, isModalOpen }) => {
  let currentAccordion = 1
  let sources = []
  const courseImage = `/features/comingsoon/coming-soon-surveys.jpg`
  const [courseToggler, courseSetToggler] = useState({
    toggler: false,
    slide: 1,
  })
  const [isCourseModalOpen, setIsModalOpen] = useState(isModalOpen)
  const name = course.name[0].text
  let description = ""

  function openLightboxOnSlide(number) {
    courseSetToggler({
      toggler: !courseToggler.toggler,
      slide: number,
    })
  }
  if (course.description !== null) {
    description = course.description[0].text
  }
  let outlineList = []
  if (course.outline != null) {
    course.outline.forEach(element => {
      let button = <div></div>
      if (element.lesson_preview != null) {
        sources.push(element.lesson_preview.url)
        const sourcesLength = sources.length
        button = (
          <button
            role="button"
            className="h_price_btn"
            onClick={() => openLightboxOnSlide(sourcesLength)}
          >
            Preview
          </button>
        )
      } else {
        button = (
          <Link to="/signup" className="h_price_btn">
            Signup
          </Link>
        )
      }
      outlineList.push(
        <div className="h_p_list">
          <div className="h_price_item h_coursetitle" data-title="CourseTitle">
            <h5>{element.lesson_name}</h5>
          </div>

          <div className="h_price_item h_time" data-title="Duration">
            <h5>{element.lesson_duration} mins</h5>
          </div>
          <div className="h_price_item h_button">
            <h5>{button}</h5>
          </div>
        </div>
      )
    })
  }

  let screenshotList = []
  if (course.screenshots != null) {
    course.screenshots.forEach(element => {
      if (element.screenshot != null) {
        sources.push(element.screenshot.url)
        const sourcesLength = sources.length
        screenshotList.push(
          <div className="col-lg-5 col-sm-6 portfolio_item mb-30">
            <div className="portfolio_img">
              {/* <Link to={element.screenshot.url} className="img_popup">
                                <img  className="img_rounded" src={element.screenshot.url} alt="" /></Link> */}
              <div>
                <button onClick={() => openLightboxOnSlide(sourcesLength)}>
                  <img
                    className="img_rounded"
                    src={element.screenshot.url}
                    alt="course screenshot"
                  />
                </button>
              </div>
            </div>
          </div>
        )
      }
    })
  }

  let totalDuration = null

  if (course.outline != null) {
    totalDuration = 0
    course.outline.forEach(element => {
      totalDuration = totalDuration + element.lesson_duration
    })
  }

  let totalRow =
    totalDuration === null ? (
      <></>
    ) : (
      <div className="h_p_list h_p_total ">
        <div className="h_price_item h_coursetitle" data-title="CourseTitle">
          <h5>Total</h5>
        </div>

        <div className="h_price_item h_time" data-title="Duration">
          <h5>{timeConvert(totalDuration)}</h5>
        </div>
        <div className="h_price_item h_button"></div>
      </div>
    )

  function handleAccordion(newPosition) {
    console.log(newPosition)
  }

  return (
    <section className="h_pricing_area sec_pad">
      <FsLightbox
        toggler={courseToggler}
        sources={sources}
        slide={courseToggler.slide}
        onOpen={fsLightBox => setIsModalOpen(!isCourseModalOpen)}
        onClose={fsLightBox => setIsModalOpen(isCourseModalOpen)}
      />
      <div className="container">
        <div className="row">
          <div className="hosting_title text-center w-100">
            <ReactWOW animation="fadeInUp" delay="0.3s">
              <h2 className="f_p f_size_40 l_height60">{name}</h2>
            </ReactWOW>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6 h_price_body">
            <ReactWOW animation="fadeInUp" delay="0.3s">
              <h2 className="f_p  f_size_28 l_height60">Course Info</h2>
            </ReactWOW>
            <ReactWOW animation="fadeInUp" delay="0.5s">
              <p className="f_size_18 l_height30">{description}</p>
            </ReactWOW>
            <div className="row portfolio_gallery">{screenshotList}</div>
            <div className="col-lg-12">
              <div
                className="tab-content faq_content  pt_120"
                id="myTabContent"
              >
                <div
                  className="tab-pane fade show active"
                  id="purchas"
                  role="tabpanel"
                  aria-labelledby="purchas-tab"
                >
                  <h3 className="f_p f_size_24 f_500 mb_20">
                    Frequently Asked Questions
                  </h3>
                  <div id="accordion">
                    <div className="card">
                      <Collapsible
                        trigger={
                          <div className="card-header" id="headingOne">
                            <h5 className="mb-0">
                              <span className="btn btn-link collapsed">
                                How much does it cost ? Is it really free ?
                                <i className="ti-plus">+</i>
                              </span>
                            </h5>
                          </div>
                        }
                        triggerWhenOpen={
                          <div className="card-header" id="headingOne">
                            <h5 className="mb-0">
                              <span className="btn btn-link">
                                How much does it cost ? Is it really free ?
                                <i style={{ display: "block" }}>-</i>
                              </span>
                            </h5>
                          </div>
                        }
                        transitionTime="200"
                      >
                        <div className="card-body">
                          We’re not interested in charging to take safety
                          awareness courses. Your free training portal is
                          supplemented with ads to cover our costs, and
                          companies looking to implement our entire safety
                          management system ad-free (+premium features) ensure a
                          free version of our software will be around forever.
                        </div>
                      </Collapsible>
                    </div>
                    <div className="card">
                      <Collapsible
                        trigger={
                          <div className="card-header" id="headingTwo">
                            <h5 className="mb-0">
                              <span className="btn btn-link collapsed">
                                Can I add my own workers for training?
                                <i className="ti-plus">+</i>
                              </span>
                            </h5>
                          </div>
                        }
                        triggerWhenOpen={
                          <div className="card-header" id="headingTwo">
                            <h5 className="mb-0">
                              <span className="btn btn-link">
                                Can I add my own workers for training?
                                <i style={{ display: "block" }}>-</i>
                              </span>
                            </h5>
                          </div>
                        }
                        transitionTime="200"
                      >
                        <div className="card-body">
                          Sign up now and if you’re position is a safety or an
                          administrative role we’ll make sure you have the tools
                          to setup training for your workers.
                        </div>
                      </Collapsible>
                    </div>
                    <div className="card">
                      <Collapsible
                        trigger={
                          <div className="card-header" id="headingThree">
                            <h5 className="mb-0">
                              <span className="btn btn-link collapsed">
                                Do I get a ticket noting my completion at the
                                end?<i className="ti-plus">+</i>
                              </span>
                            </h5>
                          </div>
                        }
                        triggerWhenOpen={
                          <div className="card-header" id="headingThree">
                            <h5 className="mb-0">
                              <span className="btn btn-link">
                                Do I get a ticket noting my completion at the
                                end?
                                <i style={{ display: "block" }}>-</i>
                              </span>
                            </h5>
                          </div>
                        }
                        transitionTime="200"
                      >
                        <div className="card-body">
                          Sure do! Wallet sized and Certificate sized. Both will
                          be available for download upon completion of your
                          course.
                        </div>
                      </Collapsible>
                    </div>
                    <div className="card">
                      <Collapsible
                        trigger={
                          <div className="card-header" id="headingfour">
                            <h5 className="mb-0">
                              <span className="btn btn-link collapsed">
                                Is anyone able to take this training?
                                <i className="ti-plus">+</i>
                              </span>
                            </h5>
                          </div>
                        }
                        triggerWhenOpen={
                          <div className="card-header" id="headingfour">
                            <h5 className="mb-0">
                              <span className="btn btn-link">
                                Is anyone able to take this training?
                                <i style={{ display: "block" }}>-</i>
                              </span>
                            </h5>
                          </div>
                        }
                        transitionTime="200"
                      >
                        <div className="card-body">
                          Almost. You must sign up with a valid business name
                          and address so that your tickets meet minimum
                          legislation requirements. Workplace training is the
                          responsibility of the employer, so any training you
                          take while unemployed is likely not to be of any
                          benefit. If you’re unemployed, but plan on working for
                          a specific company in the near future, you could ask
                          them about signing up under their company.
                        </div>
                      </Collapsible>
                    </div>
                    <div className="card">
                      <Collapsible
                        trigger={
                          <div className="card-header" id="headingfive">
                            <h5 className="mb-0">
                              <span className="btn btn-link collapsed">
                                What if I lose my ticket in the future?
                                <i className="ti-plus">+</i>
                              </span>
                            </h5>
                          </div>
                        }
                        triggerWhenOpen={
                          <div className="card-header" id="headingfive">
                            <h5 className="mb-0">
                              <span className="btn btn-link">
                                What if I lose my ticket in the future?
                                <i style={{ display: "block" }}>-</i>
                              </span>
                            </h5>
                          </div>
                        }
                        transitionTime="200"
                      >
                        <div className="card-body">
                          You will be able to log in to your account at any time
                          in the future to re-print your ticket. There's no
                          waiting on a company to send you a new one in the mail
                          with us!
                        </div>
                      </Collapsible>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6 h_price_body">
            <ReactWOW animation="fadeInUp" delay="0.3s">
              <h2 className="f_p f_size_28 l_height60">Course Outline</h2>
            </ReactWOW>
            <div className="course_outline">
              <div className="price_head">
                <div className="p_head h_coursetitle">
                  <h5>Title</h5>
                </div>
                <div className="p_head h_time">
                  <h5>Length</h5>
                </div>

                <div className="p_head h_button"></div>
              </div>
              <div className="h_price_body">
                {outlineList}
                {totalRow}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default props => {
  // Define the Partner content returned from Prismic
  const doc = props.data.course.allCourses.edges.slice(0, 1).pop()
  if (!doc) return null

  const courseName = doc.node.name[0].text
  // BANNER PROPS
  const title = () => (
    <div>
      <span>Free </span>Safety
      <br /> Training <span>Course</span>
    </div>
  )
  const bannerProps = {
    title: title,
    subtitle: "Features > Training Courses > " + courseName,
    bannerImage: "/features/training-courses/course-details-header.jpg",
    buttonLeft: {
      text: "More Courses",
      link: "/features/training",
    },
    buttonRight: {
      text: "Take Course Now",
      link: "/signup",
    },
  }
  const seo = {
    title: doc.node.meta_title,
    description: doc.node.meta_description,
    image: doc.node.social_image ? doc.node.social_image.url : null,
    // pathname,
    // article
  }

  let isModalOpen = false

  return (
    <Layout isModalOpen={isModalOpen}>
      <SEO {...seo} {...props.data.site} />
      <Banner {...bannerProps} />
      <CourseBody course={doc.node} isModalOpen={isModalOpen} />
    </Layout>
  )
}
